<template>
  <div class="container mt-4 mb-3">
    <form @submit.prevent class="mx-md-4 mx-0">

      <div class="mb-3 form-floating">
        <input class="form-control w-100" id="user_first_name" name="user_first_name" type="text" maxlength="128"
               placeholder="First Name" autofocus
               v-model.trim="firstName" :disabled="changeNameLoading" />
        <label for="user_first_name" class="text-body">First Name</label>
      </div>
      <div class="mb-3 form-floating">
        <input class="form-control w-100" id="user_last_name" name="user_last_name" type="text" maxlength="128"
               placeholder="Last Name" autofocus
               v-model.trim="lastName" :disabled="changeNameLoading" />
        <label for="user_last_name" class="text-body">Last Name</label>
      </div>

      <div class="mb-3 form-floating">
        <input class="form-control w-100" id="user_email" name="user_email" type="email" maxlength="256"
               placeholder="user@example.com" autofocus
               v-model.trim="email" :disabled="changeNameLoading" />
        <label for="user_email" class="text-body">Email</label>
      </div>

      <div class="my-4 text-center">
        <button class="btn btn-primary-orange fw-bold" @click.prevent="updateUserDetails()"
                :disabled="changeNameLoading">Save</button>
      </div>

      <div class="text-center">
        <small>To delete your account please
          <a href="mailto:helpdesk@showcaseworkshop.com?subject=Delete+me+please">contact the Helpdesk</a>.</small>
      </div>

    </form>
  </div>
</template>

<script>

    import $ from 'jquery';
    import ScNotification from '../../shared/common/ScNotification.vue';
    import ScCommonUtil from '../../shared/common/ScCommonUtil';

    export default {
      name: 'AccountChangeContact',
      data () {
        return {
          changeNameLoading: false,
          firstName: null,
          lastName: null,
          email: null
        }
      },
      mounted () {
        this.loadUserDetails();
      },
      methods: {
        loadUserDetails () {
          this.changeNameLoading = true;
          $.ajax({
            method: 'GET', url: '/main/default/ajax_fetch_profile',
          }).done((data) => {
            this.email = data.email;
            this.firstName = data.first_name;
            this.lastName = data.last_name;
          }).always(() => {
            this.changeNameLoading = false;
          }).fail((jqXhr) => {
            ScNotification.growlXhrError(jqXhr, "loading details");
          });
        },
        updateUserDetails () {
          if (!ScCommonUtil.validateEmail(this.email))
            return ScNotification.growlErrMsg('Please enter a valid email address');

          this.changeNameLoading = true;
          $.ajax({
            method: 'POST', url: '/main/default/ajax_update_profile',
            data: {
              first_name: this.firstName,
              last_name: this.lastName,
              email_address: this.email,
            }
          }).done((data) => {
            if (data.status === 'ok') {
              ScNotification.growlSuccessMsg('Your details have been changed');
              this.$store.commit('userUpdateDetails', {
                email: data.email, displayable_name: data.displayable_name
              });
            }   else {
              ScNotification.growlErrMsg(data.message)
            }
          }).always(() => {
            this.changeNameLoading = false;
          }).fail((jqXhr) => {
            ScNotification.growlXhrError(jqXhr, "saving details");
          });
        }
      }

    }

</script>