<template>
  <div v-if="!hideSubsciptionReminder && isOnTrialPlan && currentWorkshop && $route.name !== 'settings-billing'"
       :class="['col p-3', $route.name === 'library' ? 'bg-white' : '']">

    <div class="alert text-center m-0" :class="trialOrExpiredAlertClass">

      <span v-if="trialDaysLeft >= 0" v-html="trialAlertWording"></span>

      <template v-if="currentWorkshop.expired">
        Your subscription has expired.
      </template>

      <template v-if="mainMxUwCanPerform('a:list_billing_settings')">
        <router-link class="btn fw-bold ms-3 me-2" :class="trialOrExpiredButtonClass"
                     :to="{ name: 'settings-billing',
                            params: { workshopId: mainMxCurrentWorkshopId }}">Subscribe now!</router-link>
      </template>
      <template v-else>
        Please contact your workshop admin
      </template>
      or <button class="btn btn-link p-0 pb-1" style="text-decoration: underline"
                 @click.prevent="openHelpWidget">get started</button> in 7 easy steps.
    </div>

  </div>
</template>

<script>

  /*
   *
   * don't show banner when on billing page!
   *
   * display logic, text and style:
   * - trial is expired (style danger)
   * - user is on trial plan, different stages:
   *   - start/ 14 days & 13, 12, 11 days: A whole <strong>X days</strong> left in your trial. (success style)
   *   - 10, 9, 8 days: Still <strong>X days</strong> left in your trial. (success style)
   *   - 7, 6, 5 days: There are <strong>X days</strong> left in your trial. (info style)
   *   - 4, 3, 2, 1 days: Yikes! Only <strong>X days</strong> left in your trial. (warning style)
   *   - 0 days left: This is the <strong>last day</strong> of your trial. (warning style)
   *
  */

  import MainAppMixin from '../MainAppMixin';
  import { differenceInCalendarDays } from 'date-fns';


  export default {
    name: "ScTrialSubscriptionBanner",
    mixins: [ MainAppMixin ],
    methods: {
      openHelpWidget () {
        this.$store.commit('userShowGuide', true);
      }
    },
    computed: {
      currentWorkshop () {
        return this.$store.getters.userCurrentWorkspaceObj;
      },
      hideSubsciptionReminder () {
        return this.$store.state.user.hide_subscription_alert_until > new Date().getTime();
      },
      isOnTrialPlan () {
        return (this.currentWorkshop && this.currentWorkshop.is_trial_plan);
      },
      trialDaysLeft () {
        return differenceInCalendarDays(new Date(this.currentWorkshop.expiry_date), new Date());
      },
      trialOrExpiredAlertClass () {
        if (this.currentWorkshop.expired) return 'alert-danger';
        if ([7, 6, 5].includes(this.trialDaysLeft)) return 'alert-info';
        if ([4, 3, 2, 1, 0].includes(this.trialDaysLeft)) return 'alert-warning';
        if (this.trialDaysLeft >= 8) return 'alert-success'; //in case so has an extended trial eg 20 days
        return '';
      },
      trialOrExpiredButtonClass () {
        if (this.currentWorkshop.expired) return 'btn-primary-orange';
        if ([7, 6, 5].includes(this.trialDaysLeft)) return 'btn-info';
        if ([4, 3, 2, 1, 0].includes(this.trialDaysLeft)) return 'btn-warning';
        if (this.trialDaysLeft >= 8) return 'btn-success'; //in case so has an extended trial eg 20 days
        return '';
      },
      trialDaysLeftWording () {
        return (this.trialDaysLeft === 1) ? `${this.trialDaysLeft} day` : `${this.trialDaysLeft} days`;
      },
      trialAlertWording () {
        if (this.trialDaysLeft < 0) return '';
        let alertWording = 'A whole ';
        if ([10, 9 ,8].includes(this.trialDaysLeft)) alertWording = 'Still';
        if ([7, 6, 5].includes(this.trialDaysLeft)) alertWording = '';
        if ([4, 3, 2, 1].includes(this.trialDaysLeft)) alertWording = 'Yikes! Only';
        if (this.trialDaysLeft === 0) return `This is the <strong>last day</strong> of your trial.`;
        return `${alertWording} <strong>${this.trialDaysLeftWording}</strong> left in your trial.`;
      }
    }
  }
</script>
