<template>
  <div class="container mt-4 mb-3">
    <form @submit.prevent class="mx-md-4 mx-0">

      <!-- let password helper apps know what the username is -->
      <input type="hidden" name="email" :value="$store.state.user.email" autocomplete="username"/>

      <div class="mb-3 form-floating">
        <input class="form-control w-100" id="current_pwd" name="current_pwd" type="password" maxlength="32"
               placeholder="Current Password" autocomplete="password"
               v-model.trim="currentPwd" :disabled="changePwdLoading" />
        <label for="current_pwd" class="text-body">Current Password</label>
      </div>

      <div class="mb-1 form-floating">
        <input class="form-control w-100" id="new_pwd" name="new_pwd" type="password" maxlength="32"
               placeholder="New Password" autocomplete="new_password"
               v-model.trim="newPwd" :disabled="changePwdLoading" />
        <label for="new_pwd" class="text-body">New Password</label>
      </div>
      <div class="text-muted text-center">
        <small class="">
          <ScIcon name="questionCircle"/> Password must have 6 characters minimum, 32 max.</small>
      </div>

      <div class="my-4 text-center">
        <button class="btn btn-primary-orange fw-bold" @click.prevent="changePwdNow()"
                :disabled="changePwdLoading">Save</button>
      </div>
      <div class="text-muted text-center">
        <small class="">
          <ScIcon name="infoCircle"/> Changing your password will sign you out
          <br/>on other browsers and apps.</small>
      </div>

    </form>
  </div>
</template>

<script>

    import $ from 'jquery'
    import ScIcon from '../../shared/common/ScIcon.vue';
    import ScNotification from '../../shared/common/ScNotification.vue';

    export default {
      name: 'AccountChangePassword',
      components: {ScIcon},
      data () {
        return {
          currentPwd: null,
          newPwd: null,
          changePwdLoading: false,
        }
      },
      mounted () {
        //
      },
      methods: {
        changePwdNow() {
          this.changePwdLoading = true;
          if (!this.validatePwd) return;

          $.ajax({
            method: 'post', url: '/main/default/ajax_change_pwd',
            data: {
              existing_pwd: this.currentPwd,
              new_pwd: this.newPwd
            }
          }).then((data) => {
            if (data.status === 'ok') {
              ScNotification.growlSuccessMsg('Your password has been changed.');
            } else {
              ScNotification.growlErrMsg(`Oops, we had an error ${data.message}.`);
            }
          }).fail((jqXhr) => {
            ScNotification.growlXhrError(jqXhr, 'trying to change password');
          }).always(() => {
            // reset values
            this.changePwdLoading = false;
            this.currentPwd = null;
            this.newPwd = null;
          });
        }
      },
      computed: {
        validatePwd () {
          let localError = null;
          if (!this.newPwd) localError = 'Please enter a new password';
          if (!this.currentPwd) localError = 'Please enter your existing password';
          if (this.newPwd && (this.newPwd.length < 6 || this.newPwd.length > 32 ))
            localError = 'Your new password must be at least 6 characters long (maximum length 32 characters)';

          if (localError) {
            ScNotification.growlErrMsg(localError);
            return false;
          }
          return true;
        }
      }

    }

</script>