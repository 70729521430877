<template>
    <teleport to=".sc-modals-anchor">
        <div v-vb-is:modal.show ref="scModal" @vb-hidden-bs-modal="modalWasHidden"
                @vb-hide-bs-modal="canModalHide"
                class="modal fade" tabindex="-1">
            <div class="modal-dialog modal-dialog-centered modal-sm" style="min-width: 20rem;">
                <div class="modal-content">
                    <div class="modal-body">
                        <button v-if="!sessionValidationLoading && !oauthIframeLoading && !oauthKeyValidationLoading" type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close">
                        </button>
                        <br/>
                        <div v-if="sessionValidationLoading">
                            <ScIcon name="spinnerFW" class="text-muted"/>
                            Verifying session type...
                        </div>
                        <div v-if="oauthIframeLoading">
                            <ScIcon name="spinnerFW" class="text-muted"/>
                            Refreshing authentication status...

                            <!-- hidden iframe -->
                            <iframe v-if="oauthIframeSrc" :src="oauthIframeSrc" ref="oauthIframe"
                                    style="width:0; height:0; border:0; border:none"></iframe>
                        </div>
                        <div v-if="oauthKeyValidationLoading">
                            <ScIcon name="spinnerFW" class="text-muted"/>
                            Validating authentication...
                        </div>

                        <div v-if="showLoginAgainLink">
                            <div v-if="sessionError" class="alert alert-danger">
                                {{sessionError}}
                            </div>
                            <a href="#" @click.prevent="loginAgain()" class="btn btn-outline-secondary w-100 mb-2">Log in</a>
                        </div>
                        <br/>
                    </div>
                </div>
            </div>
        </div>
    </teleport>
</template>


<script>

    import ScNotification from '../../shared/common/ScNotification.vue';
    import ScIcon from '../../shared/common/ScIcon.vue';

    export default {
        name: 'AccountSessionExpiredModal',
        components: {ScIcon},
        data: function() {
            return {
                sessionValidationLoading: false,
                showLoginAgainLink: false,
                oauthIframeLoading: false,
                oauthKeyValidationLoading: false,
                oauthIframeSrc: null,
                oauthIframeCheckTimer: null,
                sessionError: 'Your session timed out.'
            };
        },
        mounted() {
            window.addEventListener("message", this.oauthIframeMessageReceiver, false);
            this.revalidate();
        },
        beforeUnmount() {
            window.removeEventListener("message", this.oauthIframeMessageReceiver, false);
        },
        methods: {
            canModalHide(e) {
                if (this.oauthIframeLoading || this.sessionValidationLoading || this.oauthKeyValidationLoading) {
                    e.preventDefault();
                }
            },
            modalWasHidden() {
                this.$store.commit('userSessionExpiredModalToggle', false);
            },
            hideModal() {
                this.$refs.scModal.$vb.modal.hide();
            },
            revalidate() {
                this.sessionValidationLoading = true;
                this.showLoginAgainLink = false;
                this.oauthIframeLoading = false;
                this.oauthIframeSrc = null;
                this.oauthKeyValidationLoading = false;
                ScNotification.growlClear();
                this.$store.dispatch('userLoginValidateEmail', {
                    email: this.$store.state.user.email, sendCode: false
                }).then((res) => {
                    if (this.$store.state.user.user_auth_type === 'oauth_force_v1' && res && res.login_requirements_silent_url) {
                        this.oauthSilentCheck(res.login_requirements_silent_url);
                        this.oauthIframeLoading = true;
                    }   else {
                        this.showLoginAgainLink = true;
                    }
                    this.sessionValidationLoading = false;
                }).catch(() => {
                    this.sessionValidationLoading = false;
                });
            },
            loginAgain() {
                document.location.reload(true);  // this will cause login screen to be displayed hopefully
            },
            oauthSilentCheck(silentUrl) {
                this.oauthIframeLoading = true;
                this.oauthIframemessageTimeout = setTimeout(this.oauthIframeMessageTimeout, 30 * 1000);  // timeout in 30s
                this.oauthIframeSrc = silentUrl;
            },
            oauthIframeMessageTimeout() {
                this.oauthIframeLoading = false;
                this.showLoginAgainLink = true;
                clearTimeout(this.oauthIframemessageTimeout);
            },
            oauthIframeMessageReceiver(e) {
                if (e.origin !== window.location.origin) return; // ignore this it's not for us
                let o = {};
                try {
                    o = JSON.parse(e.data);
                } catch(e) {
                    //
                }
                if (!o.SHOWCASE_OAUTH_SILENT_REDIR_STATE) return;  // ignore this it's not for us

                clearTimeout(this.oauthIframemessageTimeout);
                this.oauthIframeLoading = false;
                this.oauthKeyValidationLoading = true;
                this.$store.dispatch('userRefreshAuthToken', {
                    osk: o.SHOWCASE_OAUTH_SILENT_REDIR_STATE
                }).then(() => {
                    this.hideModal();
                    this.oauthKeyValidationLoading = false;
                    document.location.reload(true);  // this will cause any failed ajax calls to be resolved hopefully
                }).catch((msg) => {
                    this.sessionError = msg;
                    this.showLoginAgainLink = true;
                    this.oauthKeyValidationLoading = false;
                });
            }
        },
    };


</script>

