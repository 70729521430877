<template>
  <div>
    <div v-if="userPrefs" :class="['py-0 bg-white', submenu ? '' : 'border-bottom']">

      <div class="sc-top-level-bg">
        <div class="container text-light">
          <ul v-if="mainMxCurrentWorkshopId" class="nav nav-pills nav-fill sc-top-level-nav">
            <li class="nav-item ps-md-4" v-if="mainMxUwCanPerform('a:list_pres_for_edit')">
              <router-link
                  :class="['nav-link text-white', $route.name === 'workshop-home' ? 'active fw-bold':'']"
                  :to="{ name: 'workshop-home', params: { workshopId: userPrefs.currentWorkshopId }}">Home</router-link></li>
            <li class="nav-item" v-if="mainMxUwCanPerform('a:list_pres_for_view')">
              <router-link
                  :class="['nav-link text-white', $route.name === 'viewer-list' ? 'active fw-bold':'']"
                  :to="{name: 'viewer-list', params: { workshopId: mainMxCurrentWorkshopId }}">Present</router-link></li>
            <li class="nav-item" v-if="mainMxUwCanPerform('a:list_files')">
              <router-link
                  :class="['nav-link text-white', $route.name === 'library' ? 'active fw-bold':'']"
                  :to="{ name: 'library', params: { workshopId: mainMxCurrentWorkshopId }}">Files</router-link></li>

            <li class="nav-item" v-if="mainMxUwCanPerform('a:list_users')">
              <router-link
                  :class="['nav-link text-white', $route.name === 'user-list' ? 'active fw-bold':'']"
                  :to="{ name: 'user-list', params: { workshopId: mainMxCurrentWorkshopId }}">Users</router-link></li>
            <li class="nav-item" v-if="mainMxUwCanPerform('a:list_pres_for_view')">
              <router-link
                  :class="['nav-link text-white', submenu === 'sharing' ? 'active fw-bold':'']"
                  :to="{ name: 'share-content', params: { workshopId: mainMxCurrentWorkshopId }}">Sharing</router-link></li>

            <li class="nav-item pe-0" v-if="mainMxUwCanPerform('a:list_analytics')">
              <router-link
                  :class="['nav-link text-white', submenu === 'reporting' ? 'active fw-bold':'']"
                  :to="{ name: 'reporting-analytics', params: { workshopId: mainMxCurrentWorkshopId }}">Reporting</router-link>
            </li>
            <li class="nav-item pe-0" v-else-if="mainMxUwCanPerform('a:list_own_activity')">
              <router-link
                  :class="['nav-link text-white', submenu === 'reporting' ? 'active fw-bold':'']"
                  :to="{ name: 'reporting-activity', params: { workshopId: mainMxCurrentWorkshopId }}">Reporting</router-link>
            </li>
            <li class="nav-item text-center" v-if="mainMxUwCanPerform('a:list_pres_for_view')">
              <router-link
                  :class="['nav-link text-white', $route.name === 'search-all' ? 'active fw-bold':'']"
                  :to="{name: 'search-all', params: { workshopId: mainMxCurrentWorkshopId }}">
                <ScIcon name="search"/></router-link></li>

          </ul>
        </div>
      </div>

      <div class="container d-flex justify-content-between">
        <div class="d-flex align-items-center">
          <router-link v-if="mainMxCurrentWorkshopId"
                       :to="{ name: 'workshop-home', params: { workshopId: mainMxCurrentWorkshopId }}"
                       class="navbar-brand mx-2 mx-md-3">
            <img alt="Logo" :src="mainMxThemedAssetUrl('/logo-v2.svg')" style="width: 50px; height: 50px" />
          </router-link>
          <div v-else class="navbar-brand mx-3">
            <img alt="Logo" :src="mainMxThemedAssetUrl('/logo-v2.svg')" style="width: 50px; height: 50px" />
          </div>
        </div>
        <div class="d-flex align-items-center text-truncate">
          <h1 class="p-2 mx-0 mx-md-3 text-truncate fw-normal">
            <template v-if="submenu === 'reporting'"><strong>Reporting</strong></template>
            <template v-else-if="submenu === 'settings'"><strong>Settings</strong></template>
            <template v-else-if="submenu === 'sharing'"><strong>Sharing</strong></template>
            <template v-else><strong>{{headingPart1}}</strong> {{headingPart2}}</template>
          </h1>
        </div>
        <div class="d-flex align-items-center">
          <TreeCounter v-if="mainMxCurrentWorkshopId && mainMxUwCanPerform('a:list_pres_for_view')" :workspaceId="mainMxCurrentWorkshopId" />
          <a v-if="!isConfirmPage" href="#" @click.prevent="showUserSettings()" class="text-muted"
             v-sc-tooltip="'Account Settings'"><ScIcon name="cogSmall" class="mx-2"/></a>
        </div>
      </div>

    </div>

    <div v-if="mainMxCurrentWorkshopId && $route.meta && submenu === 'settings'" class="sc-sub-level-nav bg-white px-2 pb-1 border-bottom">
      <ul class="nav nav-tabs nav-fill ms-auto me-auto">
        <li class="nav-item bg-white ms-3" v-if="mainMxUwCanPerform('a:list_branding_settings')">
          <router-link
              :class="['nav-link h4 mb-0 bg-white', $route.name === 'settings-branding' ? 'active fw-bold':'']"
              :to="{ name: 'settings-branding', params: { workshopId: mainMxCurrentWorkshopId }}">Email Settings</router-link>
        </li>
        <li class="nav-item bg-white" v-if="mainMxUwCanPerform('a:list_workspace_settings')">
          <router-link
              :class="['nav-link h4 mb-0 bg-white', $route.name === 'settings-workshop' ? 'active fw-bold':'']"
              :to="{ name: 'settings-workshop', params: { workshopId: mainMxCurrentWorkshopId }}">Workshop Settings</router-link>
        </li>
        <li class="nav-item bg-white me-3" v-if="mainMxUwCanPerform('a:list_billing_settings')">
          <router-link
              :class="['nav-link h4 mb-0 bg-white', $route.name === 'settings-billing' ? 'active fw-bold':'']"
              :to="{ name: 'settings-billing', params: { workshopId: mainMxCurrentWorkshopId }}">Subscription Settings</router-link>
        </li>
      </ul>
    </div>
    <div v-if="mainMxCurrentWorkshopId && $route.meta && submenu === 'reporting'" class="sc-sub-level-nav bg-white px-2 pb-1 border-bottom">
      <ul class="nav nav-tabs nav-fill ms-auto me-auto">
        <li class="nav-item" v-if="mainMxUwCanPerform('a:list_analytics')" :class="mainMxUwCanPerform('a:list_analytics') ? 'ms-3' : ''">
          <router-link
              :class="['nav-link h4 mb-0 bg-white', $route.name === 'reporting-analytics' ? 'active fw-bold':'']"
              :to="{ name: 'reporting-analytics', params: { workshopId: mainMxCurrentWorkshopId }}">Analytics</router-link>
        </li>
        <li class="nav-item" v-if="mainMxUwCanPerform('a:list_own_activity')" :class="!mainMxUwCanPerform('a:list_analytics') ? 'ms-3' : ''">
          <router-link
              :class="['nav-link h4 mb-0 bg-white', $route.name === 'reporting-activity' ? 'active fw-bold':'']"
              :to="{ name: 'reporting-activity', params: { workshopId: mainMxCurrentWorkshopId }}">Activity</router-link>
        </li>
        <li class="nav-item" v-if="mainMxUwCanPerform('a:list_own_form_results')">
          <router-link
              :class="['nav-link h4 mb-0 bg-white me-3', $route.name === 'reporting-forms' ? 'active fw-bold':'']"
              :to="{ name: 'reporting-forms', params: { workshopId: mainMxCurrentWorkshopId }}">Forms</router-link>
        </li>
        <li class="nav-item" v-if="mainMxUwCanPerform('a:list_own_sharing_hist')">
          <router-link
              :class="['nav-link h4 mb-0 bg-white d-none d-sm-block me-3', $route.name === 'reporting-sharing-history' ? 'active fw-bold':'']"
              :to="{ name: 'reporting-sharing-history', params: { workshopId: mainMxCurrentWorkshopId }}"><em>Sharing History</em></router-link>
        </li>
      </ul>
    </div>
    <div v-if="mainMxCurrentWorkshopId && $route.meta && submenu === 'sharing'" class="sc-sub-level-nav bg-white px-2 pb-1 border-bottom">
      <ul class="nav nav-tabs nav-fill ms-auto me-auto flex-nowrap">
        <li class="nav-item" v-if="mainMxUwCanPerform('a:list_pres_for_view')">
          <router-link
              :class="['nav-link h4 mb-0 bg-white', $route.name === 'share-content' ? 'active fw-bold':'']"
              :to="{ name: 'share-content', params: { workshopId: mainMxCurrentWorkshopId }}">Share Content</router-link>
        </li>
        <li class="nav-item" v-if="mainMxUwCanPerform('a:list_own_sharing_hist')">
          <router-link
              :class="['nav-link h4 mb-0 bg-white', $route.name === 'reporting-sharing-history' ? 'active fw-bold':'']"
              :to="{ name: 'reporting-sharing-history', params: { workshopId: mainMxCurrentWorkshopId }}">Sharing History</router-link>
        </li>
      </ul>
    </div>

    <div v-if="mainMxCurrentWorkshopId && $route.meta && submenu === 'user'" class="sc-sub-level-nav bg-white px-2 pb-1 border-bottom">
      <ul class="nav nav-tabs nav-fill ms-auto me-auto">
        <li class="nav-item ms-3">
          <router-link
              :class="['nav-link h4 mb-0 bg-white', $route.name === 'user-list' ? 'active fw-bold':'']"
              :to="{ name: 'user-list', params: { workshopId: mainMxCurrentWorkshopId }}">Users</router-link>
        </li>
        <li class="nav-item">
          <router-link
              :class="['nav-link h4 mb-0 bg-white', ($route.name === 'user-group-list' || $route.name === 'user-group-user-list') ? 'active fw-bold':'']"
              :to="{ name: 'user-group-list', params: { workshopId: mainMxCurrentWorkshopId }}">Groups</router-link>
        </li>
        <li class="nav-item me-3">
          <router-link
              :class="['nav-link h4 mb-0 bg-white', $route.name === 'user-add' ? 'active fw-bold':'']"
              :to="{ name: 'user-add', params: { workshopId: mainMxCurrentWorkshopId }}">Add Users</router-link>
        </li>
      </ul>
    </div>


    <ScTrialSubscriptionBanner></ScTrialSubscriptionBanner>

    <div v-if="$route.params.workshopId && !currentWorkshop" class="d-flex justify-content-center align-items-center" style="min-height: 30vh;">
      Unknown Workshop.
      <br/><router-link :to="{name: 'workshop-default'}">Select default</router-link>.
    </div>

    <slot v-else></slot>

    <ScHelpWidget />

    <AccountSessionExpiredModal v-if="userPrefs && userPrefs.showSessionExpiredModal"></AccountSessionExpiredModal>

    <UserSettingsModal></UserSettingsModal>
  </div>
</template>



<script>

    import MainAppMixin from '../MainAppMixin';
    import ScHelpWidget from '../global/ScHelpWidget.vue';
    import UserSettingsModal from '../account/UserSettingsModal.vue';
    import AccountSessionExpiredModal from '../account/AccountSessionExpiredModal.vue';
    import ScVueTrackEvents from '../global/ScVueTrackEvents';
    import ScIcon from '../../shared/common/ScIcon.vue';
    import TreeCounter from "../reporting/TreeCounter.vue";
    import ScTrialSubscriptionBanner from '../global/ScTrialSubscriptionBanner.vue';

    export default {
      name: 'ScNormalPageLayout',
      mixins: [MainAppMixin],
      components: {ScHelpWidget, AccountSessionExpiredModal, ScIcon, TreeCounter, UserSettingsModal, ScTrialSubscriptionBanner},
      props: {
        headingPart1: {type: String, default: null},
        headingPart2: {type: String, default: null},
        submenu: {type: String, default: null}
      },
      methods: {
        showUserSettings () {
          UserSettingsModal.modal();
          ScVueTrackEvents.trackViewerScreenView('options');
        },
      },
      computed: {
        userPrefs () {
          return this.$store.state.user;
        },
        currentWorkshop () {
          return this.$store.getters.userCurrentWorkspaceObj;
        },
        isConfirmPage  () {
          return !!this.$route.params.confirmId;
        }

      }
    }

</script>


<style>

  .sidebar-footer .list-inline-item:not(:last-child) {
    margin-right: 1rem;
  }

  .sc-top-level-bg {
    background: #008ab0;
    background: -webkit-gradient(linear, left top, right top, from(#008ab0),to(#26bcd6));
    background: linear-gradient(to right, #008ab0 0%,#26bcd6 100%);
  }

  .sc-top-level-nav .nav-link.active {
    background-color: transparent;
  }

  .sc-sub-level-nav .nav-tabs {
    max-width: 1024px;
  }
  .sc-sub-level-nav .nav-link {
    line-height: 1.5;
  }
  .sc-sub-level-nav .nav-link.active {
    ZZbackground-color: transparent;
  }


</style>

