<template>
  <div class="mt-4 mb-3">

    <div class="row g-0 px-3">
      <div class="col-8 my-1"><small>Workshop Name</small>
        <span v-if="loadingWorkshops"><ScIcon name="spinnerFW" class="ms-3 text-muted"/></span>
      </div>
      <div class="col-4 my-1 text-end"><small>Your Role</small></div>
    </div>
    <div class="row mx-auto border-top"
         v-for="(ws) in sortedWorkspaces" :key="'ws' + ws.id">
      <div class="col-9 my-3">
        <strong v-if="ws.id === selectedWorkshopId">{{ ws.name }}</strong>
        <a v-else href="#" @click.prevent="selectedWorkshopId = ws.id">{{ws.name}}</a>

        <span v-if="ws.id === mainMxCurrentWorkshopId"> (current workshop)</span>

        &nbsp; <router-link v-if="canPerformInWs('a:list_pres_for_edit', ws.id)"
                            :to="{ name: 'workshop-home', params: { workshopId: ws.id }}"
                            v-sc-tooltip="'Open in new tab'"
                            target="_blank"><ScIcon name="shareFW"/></router-link>
      </div>
      <div class="col-3 my-3 text-end">
        <span v-if="ws.role_code === 'r:admin'"
              v-sc-tooltip="'Create, edit, view all presentations, create and manage users, workshop settings and subscription info.'">
          Admin <ScIcon name="questionCircle" class="text-muted"/></span>

        <span v-if="ws.role_code === 'r:manager'"
              v-sc-tooltip="'Add and manage users, view analytics, view all presentations.'">
          Manager <ScIcon name="questionCircle" class="text-muted"/></span>

        <span v-if="ws.role_code === 'r:reporter'"
              v-sc-tooltip="'View all presentations, view reports (analytics, sharing history, forms, activity).'">
          Reporter <ScIcon name="questionCircle" class="text-muted"/></span>

        <span v-if="ws.role_code === 'r:editor'"
              v-sc-tooltip="'Manage files, create, edit and view all presentations, view analytics'">
          Editor <ScIcon name="questionCircle" class="text-muted"/></span>

        <span v-if="ws.role_code === 'r:viewer'"
              v-sc-tooltip="'View showcases and activity log'">
          Viewer <ScIcon name="questionCircle" class="text-muted"/></span>
      </div>
      <div v-if="ws.id === selectedWorkshopId" class="col-12 mb-3 text-center">
        <span v-if="canPerformInWs('a:list_pres_for_edit', ws.id)">
          <router-link
              :to="{ name: 'workshop-home', params: { workshopId: ws.id }}"
              @click.prevent="routerLinkClick">Home</router-link></span>
        <span v-else-if="canPerformInWs('a:list_pres_for_view', ws.id)">
          <router-link
              :to="{ name: 'viewer-list', params: { workshopId: ws.id }}"
              @click.prevent="routerLinkClick">Present</router-link></span>

        <span v-if="canPerformInWs('a:list_branding_settings', ws.id)">
          | <router-link
            :to="{ name: 'settings-branding', params: { workshopId: selectedWorkshopId }}"
            @click.prevent="routerLinkClick">Email Settings</router-link></span>

        <span v-if="canPerformInWs('a:list_workspace_settings', ws.id)">
          <br/><router-link
            :to="{ name: 'settings-workshop', params: { workshopId: selectedWorkshopId }}"
            @click.prevent="routerLinkClick">Workshop Settings</router-link></span>
        <span v-if="canPerformInWs('a:list_billing_settings', ws.id)">
          | <router-link
            :to="{ name: 'settings-billing', params: { workshopId: selectedWorkshopId }}"
            @click.prevent="routerLinkClick">Subscription Settings</router-link></span>

      </div>
    </div>

    <div class="mt-4 px-3"><small>New Workshop</small></div>

    <div class="alert">
      A new workshop is a new area where you can create presentations.  This new area has a separate set of
      users.  You will be given the Admin role in the new Workshop.
      Note, each workshop is billed separately.
    </div>

    <form id="new-workspace-form" @submit.prevent="">
      <div class="row g-0 px-3">
        <div class="col-12 col-sm-7">
          <input class="form-control" v-model="newWsName" id="workspace-name" name="name" type="text" maxlength="128"
                 placeholder="New Workshop Name" />
        </div>
        <div class="col-12 col-sm-5 mt-2 mt-sm-0 text-end">
          <input :disabled="creatingWorkshop" type="submit" class="btn btn-outline-secondary btn-sm-block"
                 value="Create a Workshop" @click.prevent="submitForm" />
        </div>
      </div>
      <div v-if="wsNameError" class="alert alert-danger form-error m-2">{{ wsNameError }}</div>
      <div class="row g-0 px-3">
        <small class="mt-2 text-muted"><ScIcon name="questionCircle" /> This is usually your company or team name</small>
      </div>
    </form>

  </div>

</template>

<script>
    import $ from 'jquery';
    import ScNotification from '../../shared/common/ScNotification.vue';
    import ScIcon from '../../shared/common/ScIcon.vue';
    import MainAppMixin from '../MainAppMixin';

    let DataManager = function() {
      return {
        loadDefault (vm) {
          vm.loadingWorkshops = true;
          $.ajax({
            type: 'POST', url: '/main/workspaces/ajax_workshops_list_default_v2',
            data: {workspace_id: vm.mainMxCurrentWorkshopId}

          }).done((data) => {
            vm.loadingWorkshops = false;
            vm.loadedWorkspaces = data.workspaces;
          });
        },
        createNewWorkspace (vm) {
          vm.creatingWorkshop = true;
          $.ajax({
            type: 'POST',
            url: '/main/workspaces/ajax_create',
            data: {
              workspace_id: vm.mainMxCurrentWorkshopId,
              name: vm.newWsName
            }
          }).done((data) => {
            if (data.status === 'ok') {
              vm.newWsName = null;
              ScNotification.growlSuccessMsg("New workshop created.");
              _dataManager.loadDefault(vm);
              vm.$store.dispatch('userPrefsLoad');  // user prefs have the workshop list also
            }  else {
              vm.wsNameError = data.message;
            }
          }).always(() => {
            vm.creatingWorkshop = false;
          });

        }
      }
    };

    let _dataManager = null;

    export default {
      name: "AccountWorkshopsList",
      emits: ['hide'],
      mixins: [ MainAppMixin ],
      components: {ScIcon},
      data () {
        return {
          loadedWorkspaces: [],
          wsNameError: false,
          newWsName: null,
          loadingWorkshops: false,
          creatingWorkshop: false,
          selectedWorkshopId: null,
          wasUnmounted: false,
        };
      },
      mounted () {
        _dataManager = new DataManager();
        _dataManager.loadDefault(this);
        this.selectedWorkshopId = this.mainMxCurrentWorkshopId;
        this.wasUnmounted = false;
      },
      beforeUnmount () {
        this.wasUnmounted = true;
      },
      methods: {
        routerLinkClick () {
          // we have a weird situation where the router causes the modal to unmount but only when it
          // it navigating to a different page.  Track this and emit hide if needed.
          setTimeout(() => {
            if (this && !this.wasUnmounted && this.$emit) this.$emit('hide')
          }, 150);
        },
        submitForm () {
          _dataManager.createNewWorkspace(this);
        },
        canPerformInWs (action, workshopId) {
          return this.$store.getters.userCanPerform(action, workshopId);
        },
      },
      computed: {
        sortedWorkspaces () {
          return this.loadedWorkspaces.slice(0).sort((a, b) => {
            if (this.mainMxCurrentWorkshopId === a.id) return -1;
            if (this.mainMxCurrentWorkshopId === b.id) return 1;
            return a.name.toLowerCase().localeCompare(b.name.toLowerCase());
          });
        }

      }
    }
</script>
