<template>
    <!-- class .user-settings-modal is needed for regression tests -->
    <teleport to=".sc-modals-anchor">
    <div v-if="isModalShown" ref="scModal" v-vb-is:modal.show
         @vb-hidden-bs-modal="isModalShown=false" @vb-hide-bs-modal="canModalHide"
         class="modal fade user-settings-modal" tabindex="-1">
        <div class="modal-dialog">
            <div v-if="logoutLoading" class="modal-content">
                <div class="modal-body">
                    <div class="text-center p-5">
                        <ScIcon name="spinnerFW" class="text-muted"/>
                    </div>
                </div>
            </div>
            <div v-if="!logoutLoading" class="modal-content">
                <div class="modal-header border-bottom-0">
                    <button class="btn-close" data-bs-dismiss="modal"></button>
                    <h4 class="modal-title w-100 text-center">Account Settings</h4>
                </div>

                <div class="modal-body pt-0 px-0">

                    <div class="nav nav-tabs nav-fill pb-3 border-bottom">
                        <a href="#" @click.prevent="showDetails=false; settingsPanel='workshops'"
                           :class="['nav-item nav-link mx-1 mt-1', settingsPanel==='workshops'?'active fw-bold':'']" >Workshops</a>
                        <a href="#" @click.prevent="showDetails=false; settingsPanel='main'"
                           :class="['nav-item nav-link mx-1 mt-1', settingsPanel==='main'?'active fw-bold':'']" >Main</a>
                        <a href="#" @click.prevent="showDetails=false; settingsPanel='pwd'"
                           :class="['d-none d-sm-block nav-item nav-link mx-1 mt-1', settingsPanel==='pwd'?'active fw-bold':'']" >Password</a>
                        <a href="#" @click.prevent="showDetails=false; settingsPanel='name'"
                           :class="['d-none d-sm-block nav-item nav-link mx-1 mt-1', settingsPanel==='name'?'active fw-bold':'']" >Name/Email</a>
                        <a href="#" @click.prevent="showDetails=true; settingsPanel='pwd'"
                           :class="['d-sm-none nav-item nav-link mx-1 mt-1', showDetails?'active fw-bold':'']" >Details</a>
                    </div>

                    <div v-if="showDetails" class="nav nav-tabs nav-fill">
                        <a href="#" @click.prevent="settingsPanel='pwd'"
                           :class="['nav-item nav-link mx-1', settingsPanel==='pwd'?'active fw-bold':'']" >Password</a>
                        <a href="#" @click.prevent="settingsPanel='name'"
                           :class="['nav-item nav-link mx-1', settingsPanel==='name'?'active fw-bold':'']" >Name/Email</a>
                    </div>

                    <div v-if="settingsPanel === 'main'" class="row g-0">
                        <div class="col-11 my-1 mx-auto text-center text-sm-left px-sm-3">
                            Showcase Workshop Website v{{mainMxAppVer}}
                        </div>

                        <div class="col-12 mt-3 mb-1">
                            <div class="row row-sc-setting mx-auto border-top border-bottom py-2">
                                <div class="col-11 col-sm-8 pt-2 pb-sm-2 mx-auto">
                                    <strong>{{userPrefs.displayable_name}}</strong>
                                    <br/><span v-if="userPrefs.displayable_name !== userPrefs.email">{{userPrefs.email}}</span>
                                    <span v-if="['oauth_force_v1'].includes(userPrefs.user_auth_type)">
                                        <br/>Logged in via OAuth <a href="#" @click.prevent="userOAuthRefresh()" class="btn btn-link">Refresh</a>
                                    </span>
                                </div>
                                <div class="col-12 col-sm-3 px-sm-3 py-sm-2 text-end">
                                    <a href="#" @click.prevent="userLogout()" class="btn btn-link">Logout</a>
                                </div>
                            </div>
                        </div>

                        <div class="col-12 mt-3 row mx-auto">
                            <div class="col-3 px-4 pt-2">
                                <ScToggleOnOff :active="email_sharing_activity"
                                               @sc-toggle="toggleUserSettingEmailSharing($event)"
                                               :loading="email_sharing_activity_loading"></ScToggleOnOff>
                            </div>
                            <div class="col-8">
                                <strong>Email sharing activity</strong>
                                <p>Email me when the shared files link is opened.</p>
                            </div>
                        </div>

                        <div class="col-12 mt-2 row mx-auto">
                            <div class="col-3 px-4 pt-2">
                                <ScToggleOnOff :active="user_marketing_opt_in"
                                               :disabled="!hasCrmEnabledWorkspaces"
                                               @sc-toggle="toggleUserSettingMarketingOptIn($event)"
                                               :loading="user_marketing_opt_in_loading"></ScToggleOnOff>
                            </div>
                            <div :class="['col-8', !hasCrmEnabledWorkspaces ? 'text-muted' : '']">
                                <strong>Email newsletters</strong>
                                <p>I would like to receive Showcase newsletters with information about new
                                    features. I understand that I can unsubscribe at any time.</p>
                            </div>

                            <template v-if="!hasCrmEnabledWorkspaces">
                                <div class="col-10 mx-auto mb-3">
                                    <ScIcon name="infoCircle" class="me-1"/>Your administrator has indicated that they'd
                                    rather you don't receive Showcase newsletters. Sorry! Feel free to check out
                                    <a href="https://blog.showcaseworkshop.com" target="_blank">blog.showcaseworkshop.com</a> for updates.
                                </div>
                            </template>
                        </div>

                        <div class="col-12 my-2 row mx-auto">
                            <div class="col-3 px-4 pt-2">
                                <ScToggleOnOff :active="email_sharing_copy"
                                               @sc-toggle="toggleUserSettingEmailSharingCopy($event)"
                                               :loading="email_sharing_copy_loading"></ScToggleOnOff>
                            </div>
                            <div class="col-8">
                                <strong>Copy me on sharing emails</strong>
                                <p>Email me a copy of sharing emails.</p>
                            </div>
                        </div>

                        <div class="col-12 mt-2 mb-1 text-center">
                            <footer class="sidebar-footer fw-bold small text-muted">
                                <nav>
                                    <ul class="list-inline">
                                        <li class="list-inline-item ms-3"><a href="https://showcaseworkshop.com/" class="fw-bold small text-muted" target="_blank">About</a></li>
                                        <li class="list-inline-item"><a href="https://github.com/ShowcaseSoftwareLtd/showcase-workshop-apis" class="fw-bold small text-muted" target="_blank">Developers</a></li>
                                        <li class="list-inline-item"><a href="https://cdn.showcaseworkshop.com/showcase-acknowledgements/current/webapp.html" class="fw-bold small text-muted" target="_blank">Acknowledgements</a></li>

                                        <template v-if="mainMxScLang==='en-vg'">
                                            <li class="list-inline-item"><a href="https://showcaseworkshop.com/vodafone/privacy/" class="fw-bold small text-muted" target="_blank">Terms of Use</a></li>
                                            <li class="list-inline-item"><a href="https://showcaseworkshop.com/vodafone/support/" class="fw-bold small text-muted" target="_blank">Help</a></li>
                                        </template>
                                        <template v-else>
                                            <li class="list-inline-item"><a href="https://showcaseworkshop.com/security/" class="fw-bold small text-muted" target="_blank">Security</a></li>
                                            <li class="list-inline-item"><a href="https://showcaseworkshop.com/privacy/" class="fw-bold small text-muted" target="_blank">Privacy</a></li>
                                            <li class="list-inline-item"><a href="https://showcaseworkshop.com/legal/" class="fw-bold small text-muted" target="_blank">Legal</a></li>
                                        </template>
                                    </ul>
                                    <div class="copyright fw-normal">&copy; {{ currentYear }} Showcase Software Limited</div>
                                </nav>
                            </footer>
                        </div>
                    </div>

                    <template v-if="settingsPanel === 'workshops'">
                        <AccountWorkshopsList @hide="hideModal()"></AccountWorkshopsList>
                    </template>

                    <template v-if="settingsPanel === 'pwd'">
                        <AccountChangePassword></AccountChangePassword>
                    </template>

                    <template v-if="settingsPanel === 'name'">
                        <AccountChangeContact></AccountChangeContact>
                    </template>


                </div>
            </div>


        </div>
    </div>
    </teleport>
</template>

<script>

    import $ from 'jquery';
    import ScNotification from '../../shared/common/ScNotification.vue';
    import ScIcon from '../../shared/common/ScIcon.vue';
    import ScToggleOnOff from '../../shared/common/ScToggleOnOff.vue';
    import MainAppMixin from '../MainAppMixin';
    import AccountChangePassword from './AccountChangePassword.vue';
    import AccountWorkshopsList from './AccountWorkshopsList.vue';
    import AccountChangeContact from './AccountChangeContact.vue';

    let _ins = null;

    export default {
        name: 'UserSettingsModal',
        mixins: [MainAppMixin],
        components: {AccountChangeContact, ScToggleOnOff, AccountChangePassword, AccountWorkshopsList, ScIcon},
        data () {
            return {
                isModalShown: false,
                settingsPanel: 'main',  // main, workshops, pwd, name, details
                showDetails: false,

                email_sharing_activity: false,
                email_sharing_activity_loading: false,
                user_marketing_opt_in: false,
                user_marketing_opt_in_loading: false,
                email_sharing_copy: false,
                email_sharing_copy_loading: false,
                logoutLoading: false
            }
        },
        mounted () {
            _ins = this;
        },
        methods: {
            canModalHide(e) {
                if (this.logoutLoading) e.preventDefault()
            },
            hideModal() {
                this.$refs.scModal.$vb.modal.hide();
            },
            setSettingsPanel () {
                let settingsPanel = 'main';
                if (this.$store.state.user.workshops.length > 2 || this.mainMxUwCanPerform('a:list_pres_for_edit')) {
                    settingsPanel = 'workshops';
                }
                this.settingsPanel = settingsPanel;
            },
            loadUserSettings () {
                this.email_sharing_activity_loading = true;
                this.user_marketing_opt_in_loading = true;
                this.email_sharing_copy_loading = true;
                $.ajax({
                    method: 'GET', url: '/main/api/v1/user-settings',
                }).done((data) => {
                    this.email_sharing_activity = data.email_sharing_activity;
                    this.user_marketing_opt_in = data.user_marketing_opt_in;
                    this.email_sharing_copy = data.email_sharing_copy;
                }).always(() => {
                    this.email_sharing_activity_loading = false;
                    this.user_marketing_opt_in_loading = false;
                    this.email_sharing_copy_loading = false;
                }).fail((jqXhr) => {
                    ScNotification.growlXhrError(jqXhr, "loading settings");
                });
            },
            toggleUserSettingEmailSharing (updatedValue) {
                this.email_sharing_activity_loading = true;
                let settings = this.userSettings;
                settings.email_sharing_activity = updatedValue;
                this.updateUserSettings(settings);
            },
            toggleUserSettingMarketingOptIn (updatedValue) {
                this.user_marketing_opt_in_loading = true;
                let settings = this.userSettings;
                settings.user_marketing_opt_in = updatedValue;
                this.updateUserSettings(settings);
            },
            toggleUserSettingEmailSharingCopy (updatedValue) {
                this.email_sharing_copy_loading = true;
                let settings = this.userSettings;
                settings.email_sharing_copy = updatedValue;
                this.updateUserSettings(settings);
            },
            updateUserSettings (settings) {
                $.ajax({
                    method: 'POST', url: '/main/api/v1/user-settings',
                    data: settings
                }).done(() => {
                    this.email_sharing_activity = settings.email_sharing_activity;
                    this.user_marketing_opt_in = settings.user_marketing_opt_in;
                    this.email_sharing_copy = settings.email_sharing_copy;
                }).always(() => {
                    this.email_sharing_activity_loading = false;
                    this.user_marketing_opt_in_loading = false;
                    this.email_sharing_copy_loading = false;
                }).fail((jqXhr) => {
                    ScNotification.growlXhrError(jqXhr, "saving settings");
                });
            },
            userOAuthRefresh () {
                this.hideModal();
                this.$store.commit('userSessionExpiredModalToggle', true);
            },
            userLogout () {
                this.logoutLoading = true;
                this.$store.dispatch('userLogout').then(() => {
                    document.location.href = '/workshop/default';
                }).catch((jqXhr) => {
                    ScNotification.growlXhrError(jqXhr, 'logging out');
                    this.logoutLoading = false;
                });
            },
            toggleBsModal() {
                return new Promise(() => {
                    //console.log('toggleBsModal', this.$refs, _ins.$el);

                    this.setSettingsPanel();
                    this.loadUserSettings();
                    this.isModalShown = true;
                });
            }
        },
        computed: {
            userPrefs () {
                return this.$store.state.user;
            },
            currentYear () {
                return (new Date()).getUTCFullYear();
            },
            userSettings () {
                return {
                    email_sharing_activity: this.email_sharing_activity,
                    user_marketing_opt_in: this.user_marketing_opt_in,
                    email_sharing_copy: this.email_sharing_copy,
                }
            },
            hasCrmEnabledWorkspaces () {
                let hasACrmEnabledWorkspace = false;
                for (let ws of this.$store.state.user.workshops) {
                    if (!ws.is_crm_disabled) {
                        hasACrmEnabledWorkspace = true;
                    }
                }
                return hasACrmEnabledWorkspace;
            }

        },


        //
        // public functions
        //
        modal() {
            return _ins.toggleBsModal()
        }

    }

</script>
