<template>
    <div>
        <transition name="sc-transition-fade">
            <div v-if="treeCount > 0" class="btn-group dropdown me-2">
                <button class="btn rounded-pill btn-outline-secondary bg-white text-center px-3"
                        data-bs-toggle="dropdown" role="button" aria-expanded="false" style="border-color: #dfdfeb !important">
                    <ScIcon name="treeAlt" class="text-success"/>
                    <small class="ms-1 text-muted">{{treeCount}}</small></button>
                <div class="dropdown-menu dropdown-menu-right p-3 text-center">
                    Number of trees you’ve planted by using Showcase. <a href="https://showcaseworkshop.com/releaf-fund/"
                                                                         target="_blank" class="btn-link">Learn more.</a>
                </div>
            </div>
        </transition>
    </div>
</template>

<script>

    import $ from 'jquery';
    import ScNotification from "../../shared/common/ScNotification.vue";
    import ScIcon from "../../shared/common/ScIcon.vue";

    let _state = {
        treeCount: 0,
        treeCounterInterval: null,
        treeCounterTimeout: null,
        lastWorkspaceId: null
    };

    export default {
        name: "TreeCounter",
        components: {ScIcon},
        props: {
            workspaceId: {type: Number, default: null},
        },
        data () {
            return _state;
        },
        mounted (){
            //console.log('TreeCount mounted', this.treeCount, this.treeCounterInterval);
            if (!(this && this.treeCount !== undefined)) return;

            if (!this.treeCounterInterval || this.lastWorkspaceId !== this.workspaceId) {
                this.loadTreeCount();
            }

            if (this.treeCounterInterval) clearInterval(this.treeCounterInterval);
            this.treeCounterInterval = setInterval(() => {
                if (!document.hidden) {
                    this.loadTreeCount();
                }
            }, 1000 * 60 * 60 * 24);  // refresh data once a day
        },
        beforeUnmount() {
            if (this.treeCounterInterval) clearInterval(this.treeCounterInterval);
            if (this.treeCounterTimeout) clearTimeout(this.treeCounterTimeout);
        },
        methods: {
            loadTreeCount () {
                //console.log('loadTreeCount');
                if (this.lastWorkspaceId !== this.workspaceId) this.treeCount = 0;
                this.lastWorkspaceId = this.workspaceId;
                if (this.treeCounterTimeout) clearTimeout(this.treeCounterTimeout);
                this.treeCounterTimeout = setTimeout(() => { // wait 5sec then load count
                    $.ajax({
                        url: "/main/analytics/ajax_get_tree_count",
                        data: {workspace_id: this.lastWorkspaceId}
                    }).done((data) => {
                        // console.log(data, 'data');
                        if (data) this.treeCount = data.tree_count;
                    }).fail((jqXhr) => {
                        ScNotification.growlXhrError(jqXhr, 'loading tree count');
                    });
                }, 5000);
            }
        },
        watch: {
            workspaceId (nv) {
                if (this.lastWorkspaceId !== nv) {
                    this.loadTreeCount();
                }
            }
        }
    }

</script>
